
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
  nextTick,
} from "vue";
import {
  getProvider,
  getStateLicenseNumberByProviderId,
} from "@/api/provider.api";
import { getOrganization } from "@/core/services/JwtService";
import Multiselect from "@vueform/multiselect";
import { getPanel } from "@/api/encounter.api";
import { getDictionary, getTypeOfBillCode } from "@/api/dictionaries.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";
import {
  Service,
  Encounter,
  ICDCodes,
  OccurrenceCodes,
  OccurrenceSpanCodes,
  ValueCodes,
  Remarks,
} from "@/modules/encounter/encounter.model";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useRouter } from "vue-router";
import { searchDictModifiers } from "@/api/code-master-modifier.api";
import { searchDictRevenueCodes } from "@/api/code-master.api";
import { searchDictIcd10s } from "@/api/code-master-icd10.api";
import { searchHCPCSDict } from "@/api/code-master-hcpcs.api";
import { getFacility } from "@/api/facility.api";
import { searchDictOccurrenceCodes } from "@/api/code-master-occurrence-code.api";
import { searchDictOccurrenceSpanCodes } from "@/api/code-master-occurrence-span-code.api";
import { searchDictValueCode } from "@/api/code-master-value-codes.api";
import { searchDictConditionCode } from "@/api/code-master-condition-codes.api";
import moment from "moment";
import HourComponent from "@/components/ABilling/TimeComponent.vue";
import { truncateAfterTwoDecimalPlaces } from "@/utility";

//effectivePayers
export interface ServiceCode {
  insuranceCompany: any;
  rate?: number;
}

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
  rate?: number;
  privatePayRate?: number;
  unit?: number;
  effectiveDate?: any;
  terminationDate?: any;
}

export interface HCPCCode {
  code: string;
  description: string;
  chargePerUnit: number;
  rate?: number;
  privatePayRate?: number;
  unit?: number;
  effectiveDate?: any;
  terminationDate?: any;
}

export interface codesDictionary {
  code: string;
  description: string;
  isActive: boolean;
}

export interface Data {
  selectedFacility?: any;
  panel: any;
  isDirty: boolean;
  isLoaded: boolean;
  encounter?: Encounter;
  isAttendingProviderIncomplete: boolean;
  isFacilityProviderIncomplete: boolean;
  encounterPrevious?: Encounter;
  patientId: string;
  updateOnly: boolean;
  isClaimFieldsTabActive: boolean;
  claims?: [];
  dict: {
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    admissionType: [];
    admissionSource: [];
    dischargeStatus: [];
    hcpcs: HCPCCode[];
    revenueCodes: RevenueCode[];
    hcpcsModifiers: {
      effectiveDate?: any;
      terminationDate?: any;
      code?: any;
    }[];
    diagnosisCodes: {
      effectiveDate?: any;
      terminationDate?: any;
      code?: any;
    }[];
    poaCodes: any[];
    encounterStatusCodes: [];
    encounterActions: [];
    boolList: { code?: boolean | null; name: string }[];
    occurrenceCodes: codesDictionary[];
    occurrenceSpanCodes: codesDictionary[];
    valueCodes: codesDictionary[];
    conditionCodes: codesDictionary[];
    licenseNumberList: any[];
  };
}

export default defineComponent({
  name: "FacilityEncounterComponent",
  components: { Multiselect, DecimalInput, DateFloatComponent, HourComponent },
  props: [
    "encounter",
    "patientId",
    "effectivePrimaryPayers",
    "isFacilityProviderIncomplete",
    "isAttendingProviderIncomplete",
  ],
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    const router = useRouter();
    let data = reactive<Data>({
      panel: { facilities: [], patients: [], providers: [] },
      isDirty: false,
      isLoaded: false,
      isAttendingProviderIncomplete: false,
      isFacilityProviderIncomplete: false,
      encounter: undefined,
      patientId: props.patientId,
      updateOnly: false,
      isClaimFieldsTabActive: true,
      dict: {
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
        hcpcs: [],
        revenueCodes: [],
        hcpcsModifiers: [],
        diagnosisCodes: [],
        encounterStatusCodes: [],
        encounterActions: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
        ],
        occurrenceCodes: [],
        occurrenceSpanCodes: [],
        poaCodes: [
          { code: "1", name: "1 - Unreported" },
          { code: "Y", name: "Y - Yes" },
          { code: "N", name: "N - No" },
          { code: "U", name: "U - Unkown" },
          { code: "W", name: "W - Undetermined" },
        ],
        valueCodes: [],
        conditionCodes: [],
        licenseNumberList: [],
      },
    });

    //Validation
    const claimReq = (data) =>
      helpers.withParams(
        { type: "claimReq", value: data },
        (value: any) => helpers.req(value) || !isClaimsFieldsRequired.value
      );

    const facilityEncounterRules = {
      encounter: {
        facilityEncounter: {
          principalDiagnosis: {
            required: helpers.withMessage("Required", required),
          },
          admittingDiagnosis: {
            required: helpers.withMessage("Required", required),
          },
          statementCoversPeriodFrom: {
            required: helpers.withMessage("Required", required),
          },
          statementCoversPeriodTo: {
            required: helpers.withMessage("Required", required),
          },
          admissionType: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          admissionSource: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          admissionDate: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          admissionHour: {
            paymentReq: helpers.withMessage("Required", required),
          },
          dischargeStatus: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          typeOfFacility: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          typeOfCare: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          frequency: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          facilityBillingProviderId: {
            paymentReq: helpers.withMessage("Required", required),
          },
          providerAcceptAssignment: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          attendingProviderId: {
            paymentReq: helpers.withMessage("Required", required),
          },
          releaseOfInformation: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          services: {
            required: helpers.withMessage("Required", required),
            minLength: minLength(1),
            $each: helpers.forEach({
              revenueCode: {
                required: helpers.withMessage("Required", required),
              },
              description: {
                required: helpers.withMessage("Required", required),
              },
              serviceDate: {
                required: helpers.withMessage("Required", required),
              },
              daysOrUnits: {
                required: helpers.withMessage("Required", required),
                minLength: minLength(1),
              },
              chargePerUnit: {
                required: helpers.withMessage("Required", required),
                minLength: minLength(1),
              },
            }),
          },
        },
      },
    };

    const v$ = useVuelidate(facilityEncounterRules, data as never);
    //Validation

    //Watch
    watch(
      () => props.encounter?.primaryPayer,
      async (currentValue, oldValue) => {
        data.encounter?.facilityEncounter?.services?.forEach(async (serive) => {
          await setSericeRate(serive);
        });
      }
    );
    watch(
      () => props.encounter?.facilityId,
      async (currentValue, oldValue) => {
        await getHCPCSDict().then(() => {
          data.dict.occurrenceCodes = data.dict.occurrenceCodes.filter(
            (code) => code.isActive
          );
          data.dict.occurrenceSpanCodes = data.dict.occurrenceSpanCodes.filter(
            (code) => code.isActive
          );
          data.dict.valueCodes = data.dict.valueCodes.filter(
            (code) => code.isActive
          );
          data.dict.conditionCodes = data.dict.conditionCodes.filter(
            (code) => code.isActive
          );
        });
        data.encounter?.facilityEncounter?.services?.forEach((service) => {
          service.hcpcs = null;
          service.revenueCode = null;
        });
      }
    );

    watch(
      () => data.encounter?.facilityEncounter?.services,
      (currentValue, oldValue) => {
        if (data.encounter?.facilityEncounter?.services && oldValue)
          updateDates();
      },
      { deep: true }
    );

    watch(
      () => props.encounter,
      async () => {
        if (props.encounter) {
          data.encounterPrevious = undefined;
          await intFacilityEncounter(false);
        }
      }
    );

    watch(
      () => data.encounter?.facilityEncounter,
      (currentValue, oldValue) => {
        ctx.emit("facilityEncounterChanged", data.encounter);
        if (
          oldValue &&
          data.encounter &&
          data.encounter.facilityEncounter &&
          data.encounterPrevious &&
          JSON.stringify(data.encounter.facilityEncounter) !=
            JSON.stringify(data.encounterPrevious)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    //Watch

    //Init
    onMounted(async () => {
      organizationId.value = getOrganization();
      await getDict();
      if (props.encounter) {
        await getHCPCSDict();
        await intFacilityEncounter(false);
      }
      if (
        props.isAttendingProviderIncomplete ||
        props.isFacilityProviderIncomplete
      ) {
        data.isAttendingProviderIncomplete =
          props.isAttendingProviderIncomplete;
        data.isFacilityProviderIncomplete = props.isFacilityProviderIncomplete;
      }
      if (
        data.encounter?.facilityEncounter &&
        !data.encounter?.facilityEncounter?.admissionHour
      ) {
        data.encounter.facilityEncounter.admissionHour = 0;
      }
      data.isLoaded = true;
      await nextTick();
      v$.value.$reset();
      data.isDirty = false;
    });

    const codes = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

    async function intFacilityEncounter(hardReset: boolean) {
      //TODO this is anti-pattern for vue js, but in this case mb it makes sence
      data.encounter = props.encounter as Encounter;
      if (!data.encounter.facilityEncounter || hardReset) {
        data.encounter.facilityEncounter = {
          services: [],
          typeOfFacility: null,
          typeOfCare: null,
          frequency: null,
          principalDiagnosis: null,
          admittingDiagnosis: null,
          diagnosisList: null,
        };
      }

      if (
        data.encounter &&
        data.encounter.facilityEncounter &&
        data.encounter.facilityEncounter.additionalInformation
      ) {
        data.encounter.facilityEncounter.additionalInformation = JSON.parse(
          JSON.stringify(data.encounter.facilityEncounter.additionalInformation)
        );
        data.encounter.facilityEncounter.additionalInformation.renderProviderLicenseNumber =
          null;
        data.encounter.facilityEncounter.additionalInformation.renderProviderLicenseState =
          null;

        checkInactiveCodes(
          additionalListNames.OccurrenceCodes,
          "occurrenceCodes",
          "code"
        );
        checkInactiveCodes(
          additionalListNames.OccurrenceSpanCodes,
          "occurrenceSpanCodes",
          "code"
        );
        checkInactiveCodes(
          additionalListNames.ValueCodes,
          "valueCodes",
          "code"
        );
        checkInactiveCodes(
          additionalListNames.ConditionCodes,
          "conditionCodes",
          "value"
        );

        data.dict.licenseNumberList = await getStateLicenseNumberByProviderId(
          data.encounter.facilityEncounter.attendingProviderId
        );
        if (
          data.encounter.facilityEncounter.additionalInformation
            .renderingProviderStateLicenseNumber
        ) {
          selectRenderingProviderLicenseNumber(
            data.encounter.facilityEncounter.additionalInformation
              .renderingProviderStateLicenseNumber
          );
        }

        if (
          data.encounter.facilityEncounter.additionalInformation.eciCodesList
            .length < 3
        ) {
          let newObj = Object.assign({}, eciCodeObject);
          newObj.code =
            codes[
              data.encounter.facilityEncounter.additionalInformation.eciCodesList.length
            ];
          addListItem(additionalListNames.ECICodes, newObj);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .visitReasonCodesList.length < 3
        ) {
          let newObj = Object.assign({}, visitCodeObject);
          newObj.code =
            codes[
              data.encounter.facilityEncounter.additionalInformation.visitReasonCodesList.length
            ];
          addListItem(additionalListNames.VisitReasonCodes, newObj);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .conditionCodesList.length < 11
        ) {
          let newObj = Object.assign({}, conditionCodeObject);
          newObj.code =
            codes[
              data.encounter.facilityEncounter.additionalInformation.conditionCodesList.length
            ];
          addListItem(additionalListNames.ConditionCodes, newObj);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .occurrenceCodesList.length == 0
        ) {
          addListItem(
            additionalListNames.OccurrenceCodes,
            occurrenceCodeObject
          );
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .occurrenceSpanCodesList.length == 0
        ) {
          addListItem(
            additionalListNames.OccurrenceSpanCodes,
            occurrenceSpanCodeObject
          );
        }
        if (
          data.encounter.facilityEncounter.additionalInformation.valueCodesList
            .length == 0
        ) {
          addListItem(additionalListNames.ValueCodes, valueCodeObject);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation.remarksList
            .length == 0
        ) {
          addListItem(additionalListNames.Remarks, remarkObject);
        }
      } else {
        data.encounter.facilityEncounter.additionalInformation = {
          renderProviderLicenseNumber: null,
          originalReference: null,
          eciCodesList: [],
          visitReasonCodesList: [],
          occurrenceCodesList: [],
          occurrenceSpanCodesList: [],
          valueCodesList: [],
          conditionCodesList: [],
          remarksList: [],
        };

        if (
          data.encounter.facilityEncounter.additionalInformation.eciCodesList
            .length == 0
        ) {
          addListItem(additionalListNames.ECICodes, eciCodeObject);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .visitReasonCodesList.length == 0
        ) {
          addListItem(additionalListNames.VisitReasonCodes, visitCodeObject);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .occurrenceCodesList.length == 0
        ) {
          addListItem(
            additionalListNames.OccurrenceCodes,
            occurrenceCodeObject
          );
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .occurrenceSpanCodesList.length == 0
        ) {
          addListItem(
            additionalListNames.OccurrenceSpanCodes,
            occurrenceSpanCodeObject
          );
        }
        if (
          data.encounter.facilityEncounter.additionalInformation.valueCodesList
            .length == 0
        ) {
          addListItem(additionalListNames.ValueCodes, valueCodeObject);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation
            .conditionCodesList.length == 0
        ) {
          addListItem(additionalListNames.ConditionCodes, conditionCodeObject);
        }
        if (
          data.encounter.facilityEncounter.additionalInformation.remarksList
            .length == 0
        ) {
          addListItem(additionalListNames.Remarks, remarkObject);
        }
      }

      if (data.encounter && data.encounter.facilityId) {
        data.selectedFacility = await getFacility(data.encounter.facilityId);
      }

      if (data.encounter.facilityEncounter) {
        //Copy services to local property
        data.encounter.facilityEncounter.services = JSON.parse(
          JSON.stringify(data.encounter.services)
        );

        //updateDates();
      }

      if (data.encounter.professionalEncounter) {
        data.encounter.professionalEncounter.services = JSON.parse(
          JSON.stringify(data.encounter.services)
        );
        updateDates();
      }

      if (data.encounter.facilityEncounter.services.length == 0) {
        //Init first service
        addService();
        updateDates();
      }

      if (data.encounter?.facilityEncounter?.principalDiagnosis) {
        if (
          !data.dict.diagnosisCodes.some(
            (diagnosis) =>
              diagnosis.code ==
              data.encounter?.facilityEncounter?.principalDiagnosis
          )
        ) {
          var principalDiagnosisCode = {
            code: data.encounter?.facilityEncounter?.principalDiagnosis,
          };
          data.dict.diagnosisCodes.push(principalDiagnosisCode);
        }
      }
      if (data.encounter?.facilityEncounter?.admittingDiagnosis) {
        if (
          !data.dict.diagnosisCodes.some(
            (diagnosis) =>
              diagnosis.code ==
              data.encounter?.facilityEncounter?.admittingDiagnosis
          )
        ) {
          var admittingDiagnosisCode = {
            code: data.encounter?.facilityEncounter?.admittingDiagnosis,
          };
          data.dict.diagnosisCodes.push(admittingDiagnosisCode);
        }
      }
      if (data.encounter?.facilityEncounter?.diagnosisList) {
        data.encounter.facilityEncounter.diagnosisList.forEach(
          (encounterDiagnosis) => {
            if (
              !data.dict.diagnosisCodes.some(
                (diagnosis) => encounterDiagnosis.code == diagnosis.code
              )
            ) {
              var additionalDiagnosisCode = { code: encounterDiagnosis.code };
              data.dict.diagnosisCodes.push(additionalDiagnosisCode);
            }
          }
        );
      }
      data.encounterPrevious = JSON.parse(
        JSON.stringify(data.encounter.facilityEncounter)
      );
    }

    function checkInactiveCodes(listName, dictListName, codeProperty) {
      const list =
        data.encounter?.facilityEncounter?.additionalInformation[listName];
      if (list) {
        data.dict[dictListName] = data.dict[dictListName].filter(
          (code) =>
            code.isActive || list.some((c) => c[codeProperty] == code.code)
        );
      }
    }

    async function getDict() {
      data.panel = await getPanel();
      data.panel.facilities = data.panel.facilities.filter((c) => {
        return c.billingProvider;
      });
      data.panel.providers = data.panel.providers.filter((c) => {
        return c.attending;
      });

      data.dict.encounterActions = store.getters.allEncounterActions;
      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;

      const typeOfBillCodes = await getTypeOfBillCode();

      data.dict.typeOfFacility = typeOfBillCodes.filter((item) => {
        return item.level == 1;
      });
      data.dict.typeOfCare = typeOfBillCodes.filter((item) => {
        return item.level == 2;
      });
      data.dict.frequency = typeOfBillCodes.filter((item) => {
        return item.level == 3;
      });
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
    }

    async function getHCPCSDict() {
      if (props.encounter?.facilityId) {
        data.dict.hcpcsModifiers = await searchDictModifiers({
          search: "",
          facilityId: props.encounter?.facilityId,
        });
        data.dict.hcpcs = await searchHCPCSDict({
          facilityId: props.encounter?.facilityId,
          search: "",
          codePriority: 1,
        });
        data.dict.revenueCodes = await searchDictRevenueCodes({
          facilityId: props.encounter?.facilityId,
          search: "",
        });
        data.dict.diagnosisCodes = await searchDictIcd10s({
          search: "",
          facilityId: props.encounter?.facilityId,
        });

        data.dict.occurrenceCodes = await searchDictOccurrenceCodes({
          search: "",
          facilityId: props.encounter?.facilityId,
          displayInactive: true,
        });
        data.dict.occurrenceSpanCodes = await searchDictOccurrenceSpanCodes({
          search: "",
          facilityId: props.encounter?.facilityId,
          displayInactive: true,
        });
        data.dict.valueCodes = await searchDictValueCode({
          search: "",
          facilityId: props.encounter?.facilityId,
          displayInactive: true,
        });
        data.dict.conditionCodes = await searchDictConditionCode({
          search: "",
          facilityId: props.encounter?.facilityId,
          displayInactive: true,
        });
      } else {
        data.dict.hcpcs = [];
        data.dict.revenueCodes = [];
        data.dict.diagnosisCodes = [];
        data.dict.occurrenceCodes = [];
        data.dict.occurrenceSpanCodes = [];
        data.dict.valueCodes = [];
        data.dict.conditionCodes = [];
      }
    }
    //Init

    //#region Dict codes
    function getModifierPerService(service) {
      const newList = JSON.parse(JSON.stringify(data.dict.hcpcsModifiers));
      if (service.modifiersList)
        service.modifiersList.forEach((modifier) => {
          if (
            !newList.find((item) => {
              return item.code == modifier;
            })
          ) {
            const index = service.modifiersList.indexOf(modifier);
            if (index > -1) {
              service.modifiersList.splice(index, 1);
            }
          }
        });

      return newList;
    }

    function getRevenueCodesPerService(service) {
      const newList = JSON.parse(JSON.stringify(data.dict.revenueCodes));
      var inActiveCode = false;
      if (data.encounter?.facilityEncounter?.services) {
        data.encounter.facilityEncounter.services.forEach((serviceLine) => {
          if (
            serviceLine.revenueCode &&
            serviceLine.revenueCode != "" &&
            !newList.some(
              (revenueCode) => serviceLine.revenueCode == revenueCode.code
            )
          ) {
            newList.push(serviceLine.revenueCode);
            inActiveCode = true;
          }
        });
      }
      if (
        !newList.find((item) => {
          return item.code == service.revenueCode;
        }) &&
        !inActiveCode
      )
        service.revenueCode = "";
      return newList;
    }

    function getHCPCsPerService(service) {
      const newList = JSON.parse(JSON.stringify(data.dict.hcpcs));
      var inActiveCode = false;
      if (data.encounter?.facilityEncounter?.services) {
        data.encounter.facilityEncounter.services.forEach((serviceLine) => {
          if (
            serviceLine.hcpcs &&
            serviceLine.hcpcs != "" &&
            !newList.some((hcpcs) => serviceLine.hcpcs == hcpcs.code)
          ) {
            newList.push(serviceLine.hcpcs);
            inActiveCode = true;
          }
        });
      }
      if (
        !newList.find((item) => {
          return item.code == service.hcpcs;
        }) &&
        !inActiveCode
      )
        service.hcpcs = "";

      return newList;
    }
    //#endRegion

    //CRUD Service
    function addService() {
      let newServiceDate = getUTCnow();
      if (
        data.encounter &&
        data.encounter.facilityEncounter &&
        data.encounter.facilityEncounter.services.length > 0
      ) {
        let lastServiceDate = new Date(
          data.encounter.facilityEncounter.services[
            data.encounter.facilityEncounter.services.length - 1
          ].serviceDate
        );
        if (lastServiceDate.toISOString() != newServiceDate.toISOString()) {
          lastServiceDate.setDate(lastServiceDate.getDate() + 1);
          newServiceDate = lastServiceDate;
        }
      }
      if (data.encounter && data.encounter.facilityEncounter) {
        let newService = {
          revenueCode: "",
          description: "",
          hcpcs: "",
          modifiers: "",
          serviceDate: newServiceDate.toISOString(),
          chargePerUnit: 0,
          daysOrUnits: 1,
          modifiersList: null,
        } as Service;
        data.encounter.facilityEncounter.services.push(newService);
        serviceDateChanged(newService);
      }
    }

    function setType(type) {
      data.isClaimFieldsTabActive = type == 0 ? true : false;
    }

    async function removeService(service) {
      if (data.encounter?.facilityEncounter)
        data.encounter?.facilityEncounter.services.splice(
          data.encounter?.facilityEncounter.services.indexOf(service),
          1
        );
      await serviceDateChanged(service);
      setClaimsDefaults();
    }
    //CRUD Service

    //Service code changes
    async function revenueCodeChanged(code: string, service) {
      const item = data.dict.revenueCodes.find((elem) => {
        return elem.code == code;
      });
      if (item) {
        service.description = item.description;
        service.revenueCode = item.code;
      }
      await setSericeRate(service);
    }

    async function hcpcsOnChange(event, service) {
      if (!event) {
        return;
      }
      service.modifiersList = [];
      const serviceCode = data.dict.hcpcs.find((item: any) => {
        return item.code == event;
      }) as any;

      if (serviceCode) {
        if (serviceCode.modifier) {
          service.modifiersList = [];
          service.modifiersList.push(serviceCode.modifier);
        }
        if (serviceCode.unit) service.daysOrUnits = serviceCode.unit;

        //defaults for service Items
        if (serviceCode.revenueCode) {
          service.revenueCode = serviceCode.revenueCode;
          revenueCodeChanged(service.revenueCode, service);
        }
      }
      await setSericeRate(service);
      setClaimsDefaults();
    }

    async function clearHcpcs(service) {
      service.modifiersList = [];
      await setSericeRate(service);
      setClaimsDefaults();
    }

    async function setSericeRate(service) {
      await nextTick();
      //priority service rate->revenue code
      const item = data.dict.revenueCodes.find((elem) => {
        return elem.code == service.revenueCode;
      });
      if (item) {
        if (item.unit) service.daysOrUnits = item.unit;
        if (isSelfPay.value) {
          if (item.privatePayRate) service.chargePerUnit = item.privatePayRate;
        } else {
          if (item.rate) service.chargePerUnit = item.rate;
        }
      }

      const serviceCode = data.dict.hcpcs.find((item: any) => {
        return item.code == service.hcpcs;
      }) as any;
      if (serviceCode) {
        if (serviceCode.unit) service.daysOrUnits = serviceCode.unit;
        if (isSelfPay.value) {
          if (serviceCode.privatePayRate)
            service.chargePerUnit = serviceCode.privatePayRate;
        } else {
          if (serviceCode.rate) service.chargePerUnit = serviceCode.rate;
        }
      }
    }
    //Service code changes

    //#region Set Predefined Filds
    function setManualRate(service, rate) {
      service.chargePerUnit = rate.rate;
    }

    let eciCodeObject = { code: codes[0], value: null } as ICDCodes;
    let visitCodeObject = { code: codes[0], value: null } as ICDCodes;
    let conditionCodeObject = { code: codes[0], value: null } as ICDCodes;
    let occurrenceCodeObject = { code: null, date: null } as OccurrenceCodes;
    let occurrenceSpanCodeObject = {
      code: null,
      fromDate: null,
      toDate: null,
    } as OccurrenceSpanCodes;
    let valueCodeObject = { code: null, amount: null } as ValueCodes;
    let remarkObject = { comment: null } as Remarks;

    //Set Defaults from facility
    function setDefaults(facility) {
      data.selectedFacility = facility;

      if (data.encounter && data.encounter.facilityEncounter) {
        data.encounter.facilityEncounter.principalDiagnosis = null;
        data.encounter.facilityEncounter.admittingDiagnosis = null;
        data.encounter.facilityEncounter.facilityBillingProviderId = null;
        data.encounter.facilityEncounter.diagnosisList = null;
        data.encounter.facilityEncounter.admissionType = null;
        data.encounter.facilityEncounter.admissionDate = null;
        data.encounter.facilityEncounter.admissionHour = 0;

        data.encounter.facilityEncounter.services = [];
        //Init first service
        addService();
        updateDates();

        data.encounter.facilityEncounter.attendingProviderId = null;
        data.encounter.facilityEncounter.admissionSource = null;
        data.encounter.facilityEncounter.dischargeStatus = null;
        data.encounter.facilityEncounter.providerAcceptAssignment = true;
        data.encounter.facilityEncounter.releaseOfInformation = true;

        if (data.encounter.facilityEncounter.additionalInformation) {
          data.dict.licenseNumberList = [];
          data.encounter.facilityEncounter.additionalInformation.renderProviderLicenseNumber =
            null;
          data.encounter.facilityEncounter.additionalInformation.renderProviderLicenseState =
            null;
          data.encounter.facilityEncounter.additionalInformation.renderingProviderStateLicenseNumber =
            null;
          data.encounter.facilityEncounter.additionalInformation.originalReference =
            null;
          data.encounter.facilityEncounter.additionalInformation.eciCodesList =
            [];
          data.encounter.facilityEncounter.additionalInformation.visitReasonCodesList =
            [];
          data.encounter.facilityEncounter.additionalInformation.occurrenceCodesList =
            [];
          data.encounter.facilityEncounter.additionalInformation.occurrenceSpanCodesList =
            [];
          data.encounter.facilityEncounter.additionalInformation.valueCodesList =
            [];
          data.encounter.facilityEncounter.additionalInformation.conditionCodesList =
            [];
          data.encounter.facilityEncounter.additionalInformation.remarksList =
            [];

          if (
            data.encounter.facilityEncounter.additionalInformation.eciCodesList
              .length == 0
          ) {
            addListItem(additionalListNames.ECICodes, eciCodeObject);
          }
          if (
            data.encounter.facilityEncounter.additionalInformation
              .visitReasonCodesList.length == 0
          ) {
            addListItem(additionalListNames.VisitReasonCodes, visitCodeObject);
          }
          if (
            data.encounter.facilityEncounter.additionalInformation
              .occurrenceCodesList.length == 0
          ) {
            addListItem(
              additionalListNames.OccurrenceCodes,
              occurrenceCodeObject
            );
          }
          if (
            data.encounter.facilityEncounter.additionalInformation
              .occurrenceSpanCodesList.length == 0
          ) {
            addListItem(
              additionalListNames.OccurrenceSpanCodes,
              occurrenceSpanCodeObject
            );
          }
          if (
            data.encounter.facilityEncounter.additionalInformation
              .valueCodesList.length == 0
          ) {
            addListItem(additionalListNames.ValueCodes, valueCodeObject);
          }
          if (
            data.encounter.facilityEncounter.additionalInformation
              .conditionCodesList.length == 0
          ) {
            addListItem(
              additionalListNames.ConditionCodes,
              conditionCodeObject
            );
          }
          if (
            data.encounter.facilityEncounter.additionalInformation.remarksList
              .length == 0
          ) {
            addListItem(additionalListNames.Remarks, remarkObject);
          }
        }

        if (facility.attendingProviderId) {
          data.encounter.facilityEncounter.attendingProviderId =
            facility.attendingProviderId;
          setRenderingProviderLicenseNumber(facility.attendingProviderId);
        }
        if (facility.billingProvider && facility.isDefault)
          data.encounter.facilityEncounter.facilityBillingProviderId =
            facility.id;
        if (facility.facilityAdmissionSource)
          data.encounter.facilityEncounter.admissionSource =
            facility.facilityAdmissionSource;
        if (facility.facilityDischargeStatus)
          data.encounter.facilityEncounter.dischargeStatus =
            facility.facilityDischargeStatus;
        if (facility.facilityProviderAcceptAssignment != null)
          data.encounter.facilityEncounter.providerAcceptAssignment =
            facility.facilityProviderAcceptAssignment;
        if (facility.facilityReleaseOfInformation != null)
          data.encounter.facilityEncounter.releaseOfInformation =
            facility.facilityReleaseOfInformation;
      }

      setClaimsDefaults();
    }

    enum additionalListNames {
      ECICodes = "eciCodesList",
      VisitReasonCodes = "visitReasonCodesList",
      OccurrenceCodes = "occurrenceCodesList",
      OccurrenceSpanCodes = "occurrenceSpanCodesList",
      ValueCodes = "valueCodesList",
      ConditionCodes = "conditionCodesList",
      Remarks = "remarksList",
    }

    async function addListItem(listName, newCode) {
      if (data.encounter?.facilityEncounter) {
        let newObj = Object.assign({}, newCode);
        data.encounter.facilityEncounter.additionalInformation[listName].push(
          newObj
        );
      }
      await nextTick();
    }

    async function removeListItem(listName, code) {
      if (data.encounter?.facilityEncounter) {
        const codeList =
          data.encounter.facilityEncounter.additionalInformation[listName];
        codeList.splice(codeList.indexOf(code), 1);
      }
      await nextTick();
    }

    async function codeSelected(listName, maxListLength) {
      addEmptyCode(listName, maxListLength);
      await nextTick();
    }

    function addEmptyCode(listName, maxListLength) {
      const codeList =
        data.encounter?.facilityEncounter?.additionalInformation[listName];
      const emptyCode = codeList.find((item) => {
        return item.value == null || item.value == "";
      });
      if (!emptyCode && codeList.length < maxListLength) {
        const lastCode = codeList[codeList.length - 1];
        const index = codes.indexOf(lastCode.code);
        codeList.push({
          code: codes[index + 1],
          value: null,
        });
      }
    }

    async function codeSelectedCleared(listName, code) {
      let codeList =
        data.encounter?.facilityEncounter?.additionalInformation[listName];
      const codeIndex = codeList.indexOf(code);
      await nextTick();
      for (let i = codeList.length - 1; i > codeIndex; i--) {
        codeList.splice(i, 1);
      }
    }

    async function setClaimsDefaults() {
      await nextTick();
      if (!data.encounter?.facilityEncounter) return;

      data.encounter.facilityEncounter.typeOfFacility = null;
      data.encounter.facilityEncounter.typeOfCare = null;
      data.encounter.facilityEncounter.frequency = null;


      // set billingProvider default
      if (data.selectedFacility.billingProvider) {
        data.encounter.facilityEncounter.facilityBillingProviderId =
          data.selectedFacility.id;
      }
      else {
        data.encounter.facilityEncounter.facilityBillingProviderId =
          data.selectedFacility.facilityBillingProviderId;
      }

      //set from facility billing provider
      var facilityId = data.encounter.facilityEncounter.facilityBillingProviderId;
      if (!facilityId) {
        facilityId = data.selectedFacility.id;
      }
      const facility = await getFacility(facilityId);
      if (facility) {
        if (facility.facilityTypeOfFacility)
          data.encounter.facilityEncounter.typeOfFacility =
            facility.facilityTypeOfFacility;
        if (facility.facilityTypeOfCare)
          data.encounter.facilityEncounter.typeOfCare =
            facility.facilityTypeOfCare;
        if (facility.facilityFrequency)
          data.encounter.facilityEncounter.frequency =
            facility.facilityFrequency;
      }

      //set from HCPCS code
      const firstService = data.encounter?.facilityEncounter?.services[0];
      if (firstService) {
        const serviceCode = data.dict.hcpcs.find((item: any) => {
          return item.code == firstService.hcpcs;
        }) as any;

        if (serviceCode) {
          if (serviceCode.typeOfFacility)
            data.encounter.facilityEncounter.typeOfFacility =
              serviceCode.typeOfFacility;
          if (serviceCode.typeOfCare)
            data.encounter.facilityEncounter.typeOfCare =
              serviceCode.typeOfCare;
          if (serviceCode.frequency)
            data.encounter.facilityEncounter.frequency = serviceCode.frequency;
        }
      }
    }
    //#endregion

    //Service Date Restrictions/Operations

    //Update statementCoversPeriodFrom/to from services dates
    function updateDates() {
      if (data.encounter && data.encounter?.facilityEncounter) {
        if (
          data.encounter?.facilityEncounter.services &&
          data.encounter?.facilityEncounter.services.length > 0
        ) {
          const servicesWithDate =
            data.encounter?.facilityEncounter.services.filter((service) => {
              return service.serviceDate != undefined;
            });
          if (servicesWithDate.length == 0) {
            return;
          }
          let dates = servicesWithDate.map(
            (c) => new Date((c.serviceDate as any).toString())
          );
          let minServDate = dates
            .reduce(function (a, b) {
              return a < b ? a : b;
            })
            .toISOString();
          let maxServDate = dates
            .reduce(function (a, b) {
              return a > b ? a : b;
            })
            .toISOString();
          if (minServDate)
            data.encounter.facilityEncounter.statementCoversPeriodFrom =
              minServDate;
          if (maxServDate)
            data.encounter.facilityEncounter.statementCoversPeriodTo =
              maxServDate;
        } else {
          data.encounter.facilityEncounter.statementCoversPeriodFrom =
            undefined;
          data.encounter.facilityEncounter.statementCoversPeriodTo = undefined;
        }
      }
    }

    function getTotal(x: number) {
      return truncateAfterTwoDecimalPlaces(x);
    }

    const maxAdmDate = computed(() => {
      if (
        data.encounter &&
        data.encounter.facilityEncounter &&
        data.encounter.facilityEncounter.services &&
        data.encounter.facilityEncounter.services.length != 0
      ) {
        const servicesWithDate =
          data.encounter?.facilityEncounter.services.filter((service) => {
            return service.serviceDate != undefined;
          });
        if (servicesWithDate.length == 0) return;
        let dates = servicesWithDate.map(
          (c) => new Date((c.serviceDate as any).toString())
        );
        const minServDate = dates.reduce(function (a, b) {
          return a < b ? a : b;
        });
        if (minServDate) {
          return translateDate(minServDate);
        }
      }
      return getNow();
    });

    const maxServDate = computed(() => {
      return getNow();
    });

    const minServDate = computed(() => {
      if (data.encounter?.facilityEncounter?.admissionDate) {
        return translateDate(data.encounter.facilityEncounter.admissionDate);
      }
      return undefined;
    });

    function serviceDateChanged(service) {
      if (props.encounter.encounterType == 0)
        ctx.emit(
          "serviceDateChanged",
          data.encounter?.facilityEncounter?.services.map((p) => {
            return p.serviceDate;
          })
        );
      setSericeRate(service);
    }
    //Service Date Restrictions/Operations

    //#region payersOperation
    const isPrimaryPayerExists = computed(() => {
      return props.encounter?.primaryPayer;
    });

    const isClaimsFieldsRequired = computed(() => {
      return (
        props.encounter?.primaryPayer &&
        !props.encounter?.primaryPayer?.insuranceCompany?.privatePay
      );
    });

    const isSelfPay = computed(() => {
      return (
        !props.encounter?.primaryPayer ||
        props.encounter?.primaryPayer?.insuranceCompany?.privatePay
      );
    });

    function getPatientPayerError(service) {
      if (props.effectivePrimaryPayers) {
        const mainPayer = data.encounter?.primaryPayer;
        const servicePayer = props.effectivePrimaryPayers.items.find((c) => {
          return c.dates.find((p) => {
            return (
              new Date(p).getTime() == new Date(service.serviceDate).getTime()
            );
          });
        });
        if (
          servicePayer &&
          ((servicePayer.patientPayer &&
            data.encounter?.primaryPayer &&
            servicePayer.patientPayer.id != mainPayer.id) ||
            (servicePayer.patientPayer && !data.encounter?.primaryPayer) ||
            (!servicePayer.patientPayer && data.encounter?.primaryPayer))
        ) {
          return "A different Payer is effective for this Service Date. Please create a new Encounter for this Service";
        }
      }

      return null;
    }
    //#endregion

    async function attendingProviderChanged(providerId) {
      if (providerId) {
        var provider = await getProvider(providerId);
        if (providerId && provider && !provider.npi) {
          data.isAttendingProviderIncomplete = true;
          showProviderIncompleteDialog(providerId);
        } else {
          data.isAttendingProviderIncomplete = false;
          setRenderingProviderLicenseNumber(providerId);
        }
      }
    }

    async function showProviderIncompleteDialog(providerId) {
      if (data.isAttendingProviderIncomplete) {
        Swal.fire({
          title:
            "This Provider record is incomplete. Please add data for all mandatory fields before using this Provider",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Ok",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          data.isDirty = false;
          if (result.isConfirmed) {
            router.push({
              name: "providerPageAfterEncounter",
              params: {
                providerId: providerId.toString(),
                encounterId: data.encounter?.id?.toString(),
              },
            });
          }
        });
      }
      return;
    }

    //Utility
    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    function translateDate(date) {
      const admDate = new Date(date.toString());
      const ret = new Date(
        admDate.getUTCFullYear(),
        admDate.getUTCMonth(),
        admDate.getUTCDate(),
        0,
        0,
        0,
        0
      );
      return ret;
    }

    const isReadOnly = computed(() => {
      //return data.encounter?.status == "SubmittedToClearinghouse";
      return false;
    });

    function ifTypeofBillDisable() {
      if (data.encounter?.status != "SubmittedToClearinghouse") return false;
      return true;
    }

    async function getRenderingProviderLicenseList(providerId) {
      if (providerId) {
        data.dict.licenseNumberList = await getStateLicenseNumberByProviderId(
          providerId
        );
        return data.dict.licenseNumberList;
      }
      return [];
    }

    async function setRenderingProviderLicenseNumber(providerId) {
      const list = await getRenderingProviderLicenseList(providerId);
      clearRenderingProviderLicenseNumber();

      const facilityEncounter = data.encounter?.facilityEncounter;
      if (facilityEncounter?.additionalInformation) {
        const { additionalInformation } = facilityEncounter;
        if (list.length > 0) {
          // find default license
          let defaultLicense = list.find((item) => item["isDefault"] === true);
          if (defaultLicense) {
            additionalInformation.renderProviderLicenseNumber =
              defaultLicense["value"];
            additionalInformation.renderingProviderStateLicenseNumber =
              defaultLicense["value"];
            additionalInformation.renderProviderLicenseState =
              defaultLicense["state"];
            additionalInformation.renderingProviderStateLicenseNumberId =
              defaultLicense["id"];
          }
        }
      }
    }

    function selectRenderingProviderLicenseNumber(licenseNumber) {
      const facilityEncounter = data.encounter?.facilityEncounter;
      if (facilityEncounter?.additionalInformation) {
        const { additionalInformation } = facilityEncounter;
        let license = data.dict.licenseNumberList.find(
          (item) => item["value"] === licenseNumber
        );
        if (license) {
          additionalInformation.renderProviderLicenseNumber = license["value"];
          additionalInformation.renderingProviderStateLicenseNumber =
            license["value"];
          additionalInformation.renderProviderLicenseState = license["state"];
          additionalInformation.renderingProviderStateLicenseNumberId =
            license["id"];
        }
      }
    }

    function clearRenderingProviderLicenseNumber() {
      const facilityEncounter = data.encounter?.facilityEncounter;
      if (facilityEncounter?.additionalInformation) {
        const { additionalInformation } = facilityEncounter;
        additionalInformation.renderProviderLicenseNumber = null;
        additionalInformation.renderProviderLicenseState = null;
        additionalInformation.renderingProviderStateLicenseNumber = null;
        additionalInformation.renderingProviderStateLicenseNumberId = null;
      }
    }

    function avoidNullValues() {
      if (
        data.encounter &&
        data.encounter.facilityEncounter &&
        data.encounter.facilityEncounter.additionalInformation
      ) {
        data.encounter.facilityEncounter.additionalInformation.eciCodesList =
          data.encounter?.facilityEncounter?.additionalInformation.eciCodesList.filter(
            (item) => item.value
          );
        data.encounter.facilityEncounter.additionalInformation.visitReasonCodesList =
          data.encounter?.facilityEncounter?.additionalInformation.visitReasonCodesList.filter(
            (item) => item.value
          );
        data.encounter.facilityEncounter.additionalInformation.conditionCodesList =
          data.encounter?.facilityEncounter?.additionalInformation.conditionCodesList.filter(
            (item) => item.value
          );
        data.encounter.facilityEncounter.additionalInformation.occurrenceCodesList =
          data.encounter?.facilityEncounter?.additionalInformation.occurrenceCodesList.filter(
            (item) => item.code
          );
        data.encounter.facilityEncounter.additionalInformation.occurrenceSpanCodesList =
          data.encounter?.facilityEncounter?.additionalInformation.occurrenceSpanCodesList.filter(
            (item) => item.code
          );
        data.encounter.facilityEncounter.additionalInformation.valueCodesList =
          data.encounter?.facilityEncounter?.additionalInformation.valueCodesList.filter(
            (item) => item.code
          );
        data.encounter.facilityEncounter.additionalInformation.remarksList =
          data.encounter?.facilityEncounter?.additionalInformation.remarksList.filter(
            (item) => item.comment
          );
      }
      if (data.encounter) {
        data.encounterPrevious = JSON.parse(
          JSON.stringify(data.encounter.facilityEncounter)
        );
      }
    }

    // set toDate as empty if fromDate is greater then toDate should be null
    function setToDate(item) {
      console.log(
        item.toDate,
        item.fromDate,
        moment(item.fromDate).isAfter(item.toDate)
      );
      if (item.toDate && moment(item.fromDate).isAfter(item.toDate)) {
        item.toDate = null;
      }
    }

    return {
      attendingProviderChanged,
      organizationId,
      setSericeRate,
      clearHcpcs,
      data,
      addService,
      setType,
      hcpcsOnChange,
      isPrimaryPayerExists,
      ifTypeofBillDisable,
      intFacilityEncounter,
      getDict,
      revenueCodeChanged,
      serviceDateChanged,
      removeService,
      maxAdmDate,
      maxServDate,
      minServDate,
      getPatientPayerError,
      isClaimsFieldsRequired,
      getRevenueCodesPerService,
      getModifierPerService,
      getHCPCsPerService,
      setManualRate,
      isReadOnly,
      setDefaults,
      v$,
      setRenderingProviderLicenseNumber,
      getRenderingProviderLicenseList,
      selectRenderingProviderLicenseNumber,
      clearRenderingProviderLicenseNumber,
      codeSelected,
      codeSelectedCleared,
      additionalListNames,
      addListItem,
      removeListItem,
      occurrenceCodeObject,
      occurrenceSpanCodeObject,
      valueCodeObject,
      remarkObject,
      avoidNullValues,
      setToDate,
      getTotal,
    };
  },
});
