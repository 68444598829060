
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
  nextTick,
} from "vue";
import { getStateLicenseNumberByProviderId } from "@/api/provider.api";
import { getOrganization } from "@/core/services/JwtService";
import Multiselect from "@vueform/multiselect";
import { getPanel } from "@/api/encounter.api";
import { getDictionary } from "@/api/dictionaries.api";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { Service, Encounter } from "@/modules/encounter/encounter.model";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { Patient } from "../patient/patient.model";
import { getReferringProviders } from "@/api/referringProvider.api";
import { searchDictIcd10s } from "@/api/code-master-icd10.api";
import { searchHCPCSDict } from "@/api/code-master-hcpcs.api";
import { searchDictModifiers } from "@/api/code-master-modifier.api";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";
import { truncateAfterTwoDecimalPlaces } from "@/utility";

export interface ServiceCode {
  insuranceCompany: any;
  rate?: number;
}

export interface ProcCode {
  code: string;
  description: string;
  rate: number;
  professionalRate: number;
  professionalModifier: string;
  professionalUnitModifier: number;
  professionalPrivatePayRate?: number;
  professionalUnit: number;
  cptPayerItems: any;
  effectiveDate?: any;
  terminationDate?: any;
}

export interface DiagnosisCode {
  code: string;
  description: string;
}

export interface Data {
  facility?: any;
  isDirty: boolean;
  encounterPrevious?: string;
  diagnoses: { code; value }[];
  isLoaded: boolean;
  encounter?: Encounter;
  patientId: string;
  panel: { facilities: any[]; patients: Patient[]; providers: any };
  referringProviders: [];
  updateOnly: boolean;
  isClaimFieldsTabActive: boolean;
  claims?: [];
  dict: {
    procedureCodes: ProcCode[];
    professionalModifiers: {
      effectiveDate?: any;
      terminationDate?: any;
      code?: any;
    }[];
    diagnosisCodes: DiagnosisCode[];
    encounterStatusCodes: [];
    encounterActions: [];
    placeOfService: [];
    boolList: { code?: boolean | null; name: string }[];
    licenseNumberList: any[];
  };
}

export default defineComponent({
  name: "ProfessionalEncounterComponent",
  components: { Multiselect, DecimalInput, DateFloatComponent },
  props: ["encounter", "patientId", "effectivePrimaryPayers"],
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      diagnoses: [],
      referringProviders: [],
      isLoaded: false,
      encounter: undefined,
      patientId: props.patientId,
      panel: { facilities: [], patients: [], providers: [] },
      updateOnly: false,
      isClaimFieldsTabActive: true,
      dict: {
        procedureCodes: [],
        professionalModifiers: [],
        diagnosisCodes: [],
        encounterStatusCodes: [],
        encounterActions: [],
        placeOfService: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
        ],
        licenseNumberList: [],
      },
    });

    const claimReq = (data) =>
      helpers.withParams(
        { type: "claimReq", value: data },
        (value: any) => helpers.req(value) || !isClaimsFieldsRequired.value
      );

    const isPrimaryPayerExists = computed(() => {
      return props.encounter?.primaryPayer;
    });

    const professionalEncounterRules = {
      diagnoses: {
        $each: helpers.forEach({
          value: {
            isValid: (value, diagnosis) => {
              if (!value && diagnosis.code == "A") return false;
              return true;
            },
          },
        }),
      },
      encounter: {
        professionalEncounter: {
          billingProviderId: {
            paymentReq: helpers.withMessage("Required", required),
          },
          renderingProviderId: {
            paymentReq: helpers.withMessage("Required", required),
          },
          patientsSignatureOnFile: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          insuredsSignatureOnFile: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          providerAcceptAssignment: {
            paymentReq: helpers.withMessage("Required", claimReq(data)),
          },
          services: {
            required: helpers.withMessage("Required", required),
            minLength: minLength(1),
            $each: helpers.forEach({
              pos: { required: helpers.withMessage("Required", required) },
              procCode: { required: helpers.withMessage("Required", required) },
              description: {
                required: helpers.withMessage("Required", required),
              },
              serviceDate: {
                required: helpers.withMessage("Required", required),
              },
              serviceDateTo: {
                required: helpers.withMessage("Required", required),
              },
              daysOrUnits: {
                required: helpers.withMessage("Required", required),
                minLength: minLength(1),
              },
              chargePerUnit: {
                required: helpers.withMessage("Required", required),
              },
              diagnosisPointerList: {
                required: helpers.withMessage("Required", required),
                minLength: minLength(1),
              },
            }),
          },
        },
      },
    };

    const v$ = useVuelidate(professionalEncounterRules, data as never);

    watch(
      () => props.encounter?.facilityId,
      async () => {
        await getHCPCSandICDDict();
        data.encounter?.professionalEncounter?.services.forEach((service) => {
          service.procCode = null;
        });
        if (props.encounter && props.encounter?.facilityId) {
          const selectedFacility = data.panel.facilities.find((item) => {
            return item.id == props.encounter.facilityId;
          });
          if (selectedFacility && selectedFacility.placeOfService) {
            data.encounter?.professionalEncounter?.services.forEach(
              (service) => {
                service.pos = selectedFacility.placeOfService;
              }
            );
          }
        }
      }
    );

    watch(
      () => props.encounter,
      () => {
        if (props.encounter) {
          data.encounterPrevious = undefined;
          initProfessionalEncounter();
        }
      }
    );

    watch(
      () => data.diagnoses,
      () => {
        if (
          data.diagnoses &&
          data.encounter &&
          data.encounter.professionalEncounter
        ) {
          data.encounter.professionalEncounter.diagnoses = JSON.parse(
            JSON.stringify(
              data.diagnoses.filter((item) => {
                return item.value;
              })
            )
          );
        }
      },
      { deep: true }
    );

    watch(
      () => data.encounter?.professionalEncounter,
      (currentValue, oldValue) => {
        ctx.emit("professionalEncounterChanged", data.encounter);
        if (
          oldValue &&
          oldValue != currentValue &&
          data.encounter &&
          data.encounter.professionalEncounter &&
          data.encounterPrevious &&
          JSON.stringify(data.encounter.professionalEncounter) !=
            JSON.stringify(data.encounterPrevious)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    watch(
      () => props.encounter?.primaryPayer,
      async (currentValue, oldValue) => {
        data.encounter?.professionalEncounter?.services.forEach(
          async (service) => {
            await setServiceRate(service);
          }
        );
      }
    );

    watch(
      () => props.encounter.encounterType,
      () => {
        if (data.encounter?.professionalEncounter?.services.length == 0) {
          addService();
        }
      }
    );

    function addService() {
      if (data.encounter && data.encounter.professionalEncounter) {
        let newService = {
          pos: "",
          procCode: null,
          revenueCode: "",
          description: "",
          hcpcs: "",
          modifiers: "",
          serviceDate: undefined,
          serviceDateTo: undefined,
          diagnosisPointerList: [],
          modifiersList: [],
          chargePerUnit: 0,
          daysOrUnits: 1,
        } as Service;
        newService.diagnosisPointerList = data.diagnoses
          .filter((item) => {
            return item.value;
          })
          .map((item) => {
            return item.code;
          });
        if (data.facility && data.facility.placeOfService) {
          newService.pos = data.facility.placeOfService;
        }
        data.encounter.professionalEncounter.services.push(newService);
        serviceDateChanged(null);
      }
    }

    function availableDiagnosis(diagnosisItem) {
      let selectedDiagnosis =
        data.encounter?.professionalEncounter?.diagnoses.map((item) => {
          return item.value;
        });
      selectedDiagnosis = selectedDiagnosis.filter((item) => {
        return item != diagnosisItem.value;
      });
      const res = data.dict.diagnosisCodes.filter((item) => {
        return !selectedDiagnosis.includes(item.code);
      });
      return res;
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      await getDict();
      data.panel = await getPanel();
      data.referringProviders = await getReferringProviders();
      if (props.encounter) {
        await getHCPCSandICDDict();
        initProfessionalEncounter();
      }
      data.isLoaded = true;
      if (data.encounter?.professionalEncounter?.services?.length == 0) {
        addService();
      }

      const { encounter } = data;
      if (encounter?.professionalEncounter?.renderingProviderId) {
        const { professionalEncounter } = encounter;
        getRenderingProviderLicenseList(
          professionalEncounter.renderingProviderId
        );

        const { additionalInformation } = professionalEncounter;
      }

      data.isDirty = false;
    });

    async function initProfessionalEncounter() {
      //TODO this is anti-pattern for vue js, but in this case mb it makes sence
      data.encounter = props.encounter as Encounter;
      if (data.encounter) {
        if (!data.encounter.professionalEncounter) {
          data.encounter.professionalEncounter = {
            services: [],
            providerAcceptAssignment: true,
            patientsSignatureOnFile: true,
            insuredsSignatureOnFile: true,
          };
        }

        if (
          data.encounter.professionalEncounter?.additionalInformation
            ?.renderingProviderStateLicenseNumber
        ) {
          selectRenderingProviderLicenseNumber(
            data.encounter.professionalEncounter?.additionalInformation
              .renderingProviderStateLicenseNumber
          );
        }
        if (!data.encounter.professionalEncounter.additionalInformation) {
          data.encounter.professionalEncounter.additionalInformation = {
            renderProviderLicenseNumber: null,
          };
        }

        if (!data.encounter.professionalEncounter.services) {
          data.encounter.professionalEncounter.services = JSON.parse(
            JSON.stringify(data.encounter.services)
          );
        }

        if (
          !data.encounter.professionalEncounter.diagnoses ||
          data.encounter.professionalEncounter.diagnoses.length == 0
        ) {
          initDiagnoses();
        } else {
          data.diagnoses = JSON.parse(
            JSON.stringify(data.encounter.professionalEncounter.diagnoses)
          );
          addEmptyDiagnosis();
        }
        if (data.encounter?.professionalEncounter?.diagnoses) {
          data.encounter.professionalEncounter.diagnoses.forEach(
            (encounterDiagnosis) => {
              if (
                !data.dict.diagnosisCodes.some(
                  (diagnosis) => encounterDiagnosis.value == diagnosis.code
                )
              ) {
                var code = { code: encounterDiagnosis.value };
                data.dict.diagnosisCodes.push(code as any);
              }
            }
          );
        }

        data.encounterPrevious = JSON.parse(
          JSON.stringify(data.encounter.professionalEncounter)
        );
      }
    }

    function initDiagnoses() {
      if (data.encounter?.professionalEncounter) {
        data.encounter.professionalEncounter.diagnoses = [];
        data.diagnoses = [];
        data.diagnoses.push({ code: "A", value: "" });
      }
    }

    const codes = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

    async function diagnosisCodeSelected(item) {
      addEmptyDiagnosis();
      await nextTick();
      data.encounter?.professionalEncounter?.services.forEach((service) => {
        if (!service.diagnosisPointerList) service.diagnosisPointerList = [];
        if (!service.diagnosisPointerList.includes(item.code))
          service.diagnosisPointerList.push(item.code);
      });
    }

    function addEmptyDiagnosis() {
      let diagnosisList = data.diagnoses;
      const emptyCode = diagnosisList.find((item) => {
        return item.value == null || item.value == "";
      });
      if (!emptyCode && diagnosisList.length < 12) {
        const lastCode = diagnosisList[diagnosisList.length - 1];
        const index = codes.indexOf(lastCode.code);
        diagnosisList.push({
          code: codes[index + 1],
          value: "",
        });
      }
    }

    async function diagnosisCodeSelectedCleared(diagnosis) {
      let diagnosisList = data.diagnoses;
      const diagnosisInd = diagnosisList.indexOf(diagnosis);

      for (let i = diagnosisList.length - 1; i >= diagnosisInd; i--) {
        const code = diagnosisList[i];
        data.encounter?.professionalEncounter?.services.forEach((service) => {
          const index = service.diagnosisPointerList.indexOf(code.code);
          if (index > -1) service.diagnosisPointerList.splice(index, 1);
        });
      }
      await nextTick();
      for (let i = diagnosisList.length - 1; i > diagnosisInd; i--) {
        diagnosisList.splice(i, 1);
      }
    }

    const isSelfPay = computed(() => {
      return (
        !props.encounter?.primaryPayer ||
        props.encounter?.primaryPayer?.insuranceCompany?.privatePay
      );
    });

    async function clearCpt(service) {
      service.modifiersList = [];
      await setServiceRate(service);
    }

    async function setServiceRate(service) {
      await nextTick();
      const item = data.dict.procedureCodes.find((elem) => {
        return elem.code == service.procCode;
      });
      if (item) {
        service.description = item.description;
        if (item.professionalModifier) {
          service.modifiersList = [];
          service.modifiersList.push(item.professionalModifier);
        }
        if (item.professionalUnit) service.daysOrUnits = item.professionalUnit;
        if (isSelfPay.value) {
          if (item.professionalPrivatePayRate)
            service.chargePerUnit = item.professionalPrivatePayRate;
        } else {
          if (item.professionalRate)
            service.chargePerUnit = item.professionalRate;
        }
      } else {
        service.modifiersList = [];
      }
    }

    function setManualRate(service, rate) {
      service.chargePerUnit = rate.rate;
    }

    async function getDict() {
      data.dict.encounterActions = store.getters.allEncounterActions;
      data.dict.placeOfService = await getDictionary(11, "");

      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;
    }

    async function getHCPCSandICDDict() {
      if (props.encounter?.facilityId) {
        data.dict.professionalModifiers = await searchDictModifiers({
          search: "",
          facilityId: props.encounter?.facilityId,
        });
        data.dict.procedureCodes = await searchHCPCSDict({
          facilityId: props.encounter?.facilityId,
          search: "",
          codePriority: 0,
        });
        data.dict.diagnosisCodes = await searchDictIcd10s({
          search: "",
          facilityId: props.encounter?.facilityId,
        });
      } else {
        data.dict.procedureCodes = [];
        data.dict.diagnosisCodes = [];
      }
    }

    //#region Dict codes
    function getModifierPerService(service) {
      const newList = JSON.parse(JSON.stringify(data.dict.professionalModifiers));
      if (service.modifiersList)
        service.modifiersList.forEach((modifier) => {
          if (
            !newList.find((item) => {
              return item.code == modifier;
            })
          ) {
            const index = service.modifiersList.indexOf(modifier);
            if (index > -1) {
              service.modifiersList.splice(index, 1);
            }
          }
        });

      return newList;
    }

    function getHCPCPerService(service) {
      const newList = JSON.parse(JSON.stringify(data.dict.procedureCodes));
      var inActiveCode = false;
      if (data.encounter?.professionalEncounter?.services) {
        data.encounter.professionalEncounter.services.forEach((serviceLine) => {
          if (
            serviceLine.procCode &&
            serviceLine.procCode != "" &&
            !newList.some((hcpcs) => serviceLine.procCode == hcpcs.code)
          ) {
            newList.push(serviceLine.procCode);
            inActiveCode = true;
          }
        });
      }
      if (
        !newList.find((item) => {
          return item.code == service.procCode;
        }) &&
        !inActiveCode
      )
        service.procCode = null;
      return newList;
    }
    //#endRegion

    function removeService(service) {
      data.encounter?.professionalEncounter?.services.splice(
        data.encounter?.professionalEncounter.services.indexOf(service),
        1
      );
      serviceDateChanged(null);
    }

    function isAllDiagnosisUsed() {
      const usedDiagnosis: string[] =
        data.encounter?.professionalEncounter?.services
          .map((item) => {
            return item.diagnosisPointerList;
          })
          .flat(1);
      const notUsed = data.diagnoses.filter((item) => {
        return item.value && !usedDiagnosis.includes(item.code);
      });
      return notUsed;
    }

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    function serviceDateChanged(service) {
      if (props.encounter.encounterType == 1) {
        ctx.emit(
          "serviceDateChanged",
          data.encounter?.professionalEncounter?.services.map((p) => {
            return p.serviceDate;
          })
        );
        if (service) service.serviceDateTo = service.serviceDate;
      }
    }

    function maxServDate(service) {
      if (service.serviceDateTo) {
        return service.serviceDateTo;
      }
      return getNow();
    }

    const maxHospDate = computed(() => {
      if (data.encounter?.professionalEncounter?.hospitalizationDatesTo) {
        return data.encounter.professionalEncounter.hospitalizationDatesTo;
      }
      return getNow();
    });

    const maxDate = computed(() => {
      return getNow();
    });

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    function getPatientPayerError(service) {
      if (props.effectivePrimaryPayers) {
        const mainPayer = data.encounter?.primaryPayer;
        const servicePayer = props.effectivePrimaryPayers.items.find((c) => {
          return c.dates.find((p) => {
            return (
              new Date(p).getTime() == new Date(service.serviceDate).getTime()
            );
          });
        });
        if (
          servicePayer &&
          ((servicePayer.patientPayer &&
            data.encounter?.primaryPayer &&
            servicePayer.patientPayer.id != mainPayer.id) ||
            (servicePayer.patientPayer && !data.encounter?.primaryPayer) ||
            (!servicePayer.patientPayer && data.encounter?.primaryPayer))
        ) {
          return "A different Payer is effective for this Service Date. Please create a new Encounter for this Service";
        }
      }

      return null;
    }

    function setDefaults(facility) {
      data.facility = facility;
      if (data.encounter && data.encounter.professionalEncounter) {
        data.encounter.professionalEncounter.services.forEach((service) => {
          service.pos = null;
        });
        initDiagnoses();
        data.encounter.professionalEncounter.services = [];
        addService();

        data.encounter.professionalEncounter.dateOfOnset = null;
        data.encounter.professionalEncounter.hospitalizationDatesFrom = null;
        data.encounter.professionalEncounter.hospitalizationDatesTo = null;
        data.encounter.professionalEncounter.claimNote = null;
        data.encounter.professionalEncounter.billingProviderId = null;
        data.encounter.professionalEncounter.renderingProviderId = null;
        data.encounter.professionalEncounter.referringProviderId = null;
        data.encounter.professionalEncounter.providerAcceptAssignment = true;
        data.encounter.professionalEncounter.patientsSignatureOnFile = true;
        data.encounter.professionalEncounter.insuredsSignatureOnFile = true;

        if (data.encounter.professionalEncounter.additionalInformation) {
          data.dict.licenseNumberList = [];
          data.encounter.professionalEncounter.additionalInformation.renderProviderLicenseNumber =
            null;
          data.encounter.professionalEncounter.additionalInformation.renderProviderLicenseState =
            null;
          data.encounter.professionalEncounter.additionalInformation.renderingProviderStateLicenseNumber =
            null;
          data.encounter.professionalEncounter.additionalInformation.originalReference =
            null;
          data.encounter.professionalEncounter.additionalInformation.accidentDate =
            null;
          data.encounter.professionalEncounter.additionalInformation.lastMenstrualPeriod =
            null;
          data.encounter.professionalEncounter.additionalInformation.initialTreatmentDate =
            null;
          data.encounter.professionalEncounter.additionalInformation.lastSeenDate =
            null;
          data.encounter.professionalEncounter.additionalInformation.unableWorkFromDate =
            null;
          data.encounter.professionalEncounter.additionalInformation.unableWorkToDate =
            null;
          data.encounter.professionalEncounter.additionalInformation.additionalClaimInformation =
            null;
        }

        if (facility.placeOfService)
          data.encounter.professionalEncounter.services.forEach((service) => {
            service.pos = facility.placeOfService;
          });
        if (facility.professionalBillingProviderId)
          data.encounter.professionalEncounter.billingProviderId =
            facility.professionalBillingProviderId;
        if (facility.professionalRenderingProviderId) {
          data.encounter.professionalEncounter.renderingProviderId =
            facility.professionalRenderingProviderId;
          setRenderingProviderLicenseNumber(
            facility.professionalRenderingProviderId
          );
        }
        if (facility.professionalProviderAcceptAssignment != null)
          data.encounter.professionalEncounter.providerAcceptAssignment =
            facility.professionalProviderAcceptAssignment;
        if (facility.professionalPatientsSignatureOnFile != null)
          data.encounter.professionalEncounter.patientsSignatureOnFile =
            facility.professionalPatientsSignatureOnFile;
        if (facility.professionalInsuredsSignatureOnFile != null)
          data.encounter.professionalEncounter.insuredsSignatureOnFile =
            facility.professionalInsuredsSignatureOnFile;
      }
    }

    async function getRenderingProviderLicenseList(providerId) {
      if (providerId) {
        data.dict.licenseNumberList = await getStateLicenseNumberByProviderId(
          providerId
        );
        return data.dict.licenseNumberList;
      }
      return [];
    }

    async function setRenderingProviderLicenseNumber(providerId) {
      await getRenderingProviderLicenseList(providerId);
      //clearRenderingProviderLicenseNumber();

      // commenting out for future reference to automatically assign default state license number
      //const professionalEncounter = data.encounter?.professionalEncounter;
      //if (professionalEncounter?.additionalInformation) {
      //  const { additionalInformation } = professionalEncounter;
      //  if(list.length > 0){
      //    let defaultLicense = list.find((item) => item.isDefault);
      //    const license = defaultLicense || list[0];
      //    additionalInformation.renderProviderLicenseNumber = license.value;
      //    additionalInformation.renderProviderLicenseState = license.state;
      //    additionalInformation.renderingProviderStateLicenseNumber = JSON.stringify(license);
      //  }
      //}
    }

    async function selectRenderingProviderLicenseNumber(licenseNumber) {
      const professionalEncounter = data.encounter?.professionalEncounter;
      await getRenderingProviderLicenseList(
        professionalEncounter?.renderingProviderId
      );
      if (professionalEncounter?.additionalInformation) {
        let license = data.dict.licenseNumberList.find(
          (item) => item["value"] === licenseNumber
        );
        if (license) {
          professionalEncounter.additionalInformation.renderProviderLicenseNumber =
            license["value"];
          professionalEncounter.additionalInformation.renderingProviderStateLicenseNumber =
            license["value"];
          professionalEncounter.additionalInformation.renderProviderLicenseState =
            license["state"];
          professionalEncounter.additionalInformation.renderingProviderStateLicenseNumberId =
            license["id"];
        } else {
          professionalEncounter.additionalInformation.renderProviderLicenseNumber =
            null;
          professionalEncounter.additionalInformation.renderProviderLicenseState =
            null;
          professionalEncounter.additionalInformation.renderingProviderStateLicenseNumber =
            null;
          professionalEncounter.additionalInformation.renderingProviderStateLicenseNumberId =
            null;
        }
      }
    }

    function clearRenderingProviderLicenseNumber() {
      const professionalEncounter = data.encounter?.professionalEncounter;
      if (professionalEncounter?.additionalInformation) {
        const { additionalInformation } = professionalEncounter;
        additionalInformation.renderProviderLicenseNumber = null;
        additionalInformation.renderProviderLicenseState = null;
        additionalInformation.renderingProviderStateLicenseNumber = null;
        additionalInformation.renderingProviderStateLicenseNumberId = null;
      }
    }

    const isClaimsFieldsRequired = computed(() => {
      return (
        props.encounter?.primaryPayer &&
        !props.encounter?.primaryPayer?.insuranceCompany?.privatePay
      );
    });

    const isReadOnly = computed(() => {
      //return data.encounter?.status == "SubmittedToClearinghouse";
      return false;
    });

    function setActiveTabType(type) {
      data.isClaimFieldsTabActive = type == 0 ? true : false;
    }

    function getTotal(x: number) {
      return truncateAfterTwoDecimalPlaces(x);
    }

    return {
      getHCPCPerService,
      organizationId,
      data,
      addService,
      getDict,
      setServiceRate,
      diagnosisCodeSelected,
      isAllDiagnosisUsed,
      diagnosisCodeSelectedCleared,
      availableDiagnosis,
      maxHospDate,
      maxServDate,
      maxDate,
      removeService,
      clearCpt,
      serviceDateChanged,
      getPatientPayerError,
      setManualRate,
      setDefaults,
      initProfessionalEncounter,
      getModifierPerService,
      isClaimsFieldsRequired,
      setRenderingProviderLicenseNumber,
      selectRenderingProviderLicenseNumber,
      clearRenderingProviderLicenseNumber,
      isReadOnly,
      v$,
      setActiveTabType,
      getTotal,
    };
  },
});
